import React from 'react'
import shareIcon from "../../../src/images/shareIcon.svg"
import linkdinIcon from "../../../src/images/linkdinIcon.svg"
import twitterIcon from "../../../src/images/twitterIcon.svg"
import facebookIcon from "../../../src/images/facebookIcon.svg"

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  All
} from 'react-share'

const ShareButtons = ({title, url, twitterHandle, tags}) => {

    // console.log(url)

    return(
        <div className="bld-socialIcons">
          <div>
          <img src={shareIcon}/>
          </div>
          <FacebookShareButton url={url}>
          <img src={facebookIcon}/>
         </FacebookShareButton>

          <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
          <img src={twitterIcon}/>
          </TwitterShareButton>

          <LinkedinShareButton url={url} >
          <img src={linkdinIcon}/>
          </LinkedinShareButton>
        </div>
      )

}
export default ShareButtons