import React from "react";
import { navigate } from "gatsby";
import banner3 from "../../../../images/banner3.jpg";
import banner2 from "../../../../images/banner2.jpg";
import dateIcon from "../../../../images/dateIcon.svg";
import durationIcon from "../../../../images/durationIcon.svg";
import ShareButtons from "../../../share/share.component";
import Moment from "react-moment";
import Img from "gatsby-image";

const isBrowser = typeof window !== `undefined`
function Heading({data}) {
    const title = data.heading;
    const url = isBrowser && window.location.href;
    const twitterHandle = "_MsLinda";
    return (
        <div>
            
            <div className="bld-sec">
                <div className="bld-wrap">
                <div className="rsection1cont">
                    <div className="rsection1Title">{data.title}</div>
                    <h1 className="rsection1Heading">
                        {data.heading}
                    </h1>
                    <div className="bld-info" >
                        <div className="bld--info-wrap">
                        <div className="bld-authr-wrap">
                            <div className="authr-img-wrap">
                            <Img
                                fixed={data.Author?.picture?.localFile?.childImageSharp?.fixed}
                                style={{ borderRadius: "50%", width: 54, height: 54 }}
                                alt="AuthorImg"
                            />
                            </div>
                            
                        <div className="authr-des-wrap">
                        <h5>{data?.Author?.name}</h5>
                                <h6>{data?.Author?.designation}</h6> 
                        </div>
                        </div>                     
                       
                        <div className="bld-date">
                            <div className="bld-date-img-box">
                                <img src={dateIcon}/>
                            </div>                        
                            <Moment format="MMMM Do YYYY">{data.date}</Moment>
                        </div>
                        <div className="bld-duration">
                        <div className="bld-time-img-box">
                            <img src={durationIcon}/>
                        </div> 
                            <h6>{data.time}</h6>
                        </div>
                        </div>
                        <div> <ShareButtons title={title} url={url} twitterHandle={twitterHandle} /></div>
                    </div>
                    </div>
                    </div>
            </div>
                
            
            <div className=" bld-thumbnail-sec">
                <div className="bld-thumbnail-wrap">
                    <div className="bld-thumbnail-box">
                        <div className="left_img">
                            <Img 
                                fixed={data.blogimg[0]?.localFile?.childImageSharp?.fixed}
                                alt="Blog Img"
                                style={{ width: "40vw", height: "40vh" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Heading
