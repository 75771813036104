import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Moment from 'react-moment'
function Blog({ data, locale, slugPrefix }) {
    return (
        <div className="outer_blog_d">
            <div className="rsection6blogs">
                <div>
                    <div className="rsection6blogsTitle">
                        Blogs
                    </div>
                    <div className="rsection6blogsHeading">
                        Related Blogs
                    </div>
                    <div className="rsection6blogsCards">
                        {data.map((data, index) => {
                            return (
                                <Link to={`/${slugPrefix}${data.node.slug}/`} key={index}>
                                    <div className="rsection6blogCard" key={index}>
                                        {Array.isArray(data.node.img) ? <Img fixed={data.node.img[0].localFile.childImageSharp.fixed} className="rsection6blogsCardImage" alt="blogimage" /> : <Img fixed={data.node.img.localFile.childImageSharp.fixed} className="rsection6blogsCardImage" alt="blogimage" />}
                                        <div className="rsection6blogsCardName" >{data.node.title}</div>
                                        <div className="rsection6blogsCardTitle">{data.node.heading}</div>
                                        <div className="rsection6blogsCardDescription">{data.node.preview}</div>
                                        <div className="date_listing">
                                            <div className="date"><Moment format='MMMM Do YYYY'>{data.node.date}</Moment></div>
                                            <div className="time">{data.node.time}</div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog
