import React from "react";
import "../styles/resourceblog.css";

import { graphql } from "gatsby";
import Layout from "../components/layout";
import Heading from "../components/resource/blog/detail/Heading";
import SpeakerList from "../components/resource/blog/detail/SpeakerList";
import About from "../components/resource/blog/detail/About";
import Blog from "../components/resource/blog/detail/Blog";
import ReadMore from "../components/resource/blog/detail/ReadMore";

import SEO from "../components/seo";

function ResourceBlogDetail({ data }) {
  const getData = data.allStrapiBlogs.edges[0].node;
  const readmore = data.allStrapiResourceMainSections.edges[0].node;
  const relatedBlogs = data.relatedBlogs.edges;
  const slugPrefix = "resource/blog/";
  return (
    <Layout slug={`${slugPrefix}${getData.slug}`} locale={getData.locale}>
      <SEO
        noindexForce={false}
        description={getData.MetaDes}
        slug={`${slugPrefix}${getData.slug}`}
        locale={getData.locale}
        title={getData.heading1}
        isBlogDetail={true}
      />
      <Heading data={getData} />
      <SpeakerList data={getData} />
      <About data={getData.Author} />
      <ReadMore
        slug={getData.slug}
        locale={getData.locale}
        data={readmore.detailReadMore}
      />
      <Blog
        data={relatedBlogs}
        locale={getData.locale}
        slugPrefix={slugPrefix}
      />
    </Layout>
  );
}

export default ResourceBlogDetail;

export const query = graphql`
  query ResourceBlogDetail($slug: String!, $locale: String!) {
    allStrapiBlogs(filter: { slug: { eq: $slug }, locale: { eq: $locale } }) {
      edges {
        node {
          locale
          slug
          date
          time
          title
          MetaDes
          heading
          preview
          id
          blogimg {
            localFile {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          detailDescription
          conclusion
          heading1
          Author {
            name
            designation
            postedIn
            about
            picture {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    allStrapiResourceMainSections(
      filter: { slug: { eq: "blog" }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          detailReadMore {
            cloudmigration {
              btn
              heading
              title
              slug
            }
          }
        }
      }
    }
    relatedBlogs: allStrapiBlogs(
      filter: { slug: { ne: $slug }, locale: { eq: "en" } }
      limit: 3
    ) {
      edges {
        node {
          locale
          slug
          title
          date
          time
          heading
          preview
          id
          img: blogimg {
            localFile {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;
