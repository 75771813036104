import React from 'react'
import Img from 'gatsby-image';

function About({data}) {
    return (
        <div className="rsection4">
            <div className="rsection4cont">
                <div className="rsection4title">
                    <div className="rsection4titleCont">About {data?.name}</div>
                </div>

                <div className="rsection4DesCont">
                    <div className="rsection4profile">
                        <Img
                            fixed={data?.picture?.localFile?.childImageSharp?.fixed}
                            style={{ borderRadius: "50%", width: 84, height: 86 }}
                            alt="AuthorImg"
                        />
                        <div>{data?.name}</div>
                        <div  style={{ fontSize: "13px", opacity: 0.7 }}>{data?.designation}</div>
                    </div>
                    <div className="rsection4Des">
                        {/* <div className="resource_sect_text" style={{ marginBottom: "34px", fontSize: "16px" }}>Posted in <b>{data?.postedIn}</b></div> */}
                        <div>{data?.about}</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About
