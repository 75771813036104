import React, { createElement } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const isBrowser = typeof window !== `undefined`

  const convertMarkdownLinksToReactElements = (inputString) => {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = inputString.split(regex);
    const result = [];
  
    for (let i = 0; i < parts.length; i++) {
      if (parts[i+1]?.startsWith("http://") || parts[i+1]?.startsWith("https://")) {
        result.push(
          createElement("a", { href: parts[i + 1] }, parts[i])
        );
        i++;
      } else {
        result.push(parts[i]);
      }
    }
  
    return result;
  }
  

  
function SpeakerList({ data }) {

    const title = data.heading;
    const url = isBrowser && window.location.href;;
    const twitterHandle = "_MsLinda";
    
    return (
        <div>
            <div className="bld-desc-sec">
                <div className="bld-desc-wrap">
                    <div className="row"> 
                        <div className="col-4" />
                        <div className="col-8 bld-desc-container" >
                            <ReactMarkdown
                            className="bld-desc-one"
                            children={data.detailDescription}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                img({ ...props }) {
                                    return (
                                        <div className="blog-img-center ">
                                            <img {...props} />
                                        </div>
                                    )
                                },
                                p({ children }) {
                                    if (!!children?.length) {
                                        children.forEach((child, index) => {
                                            if (typeof child === "string") {
                                                children[index] = convertMarkdownLinksToReactElements(child);
                                            }
                                        })
                                    }
                                    return createElement("p", {}, children || [])
                                },
                                li({ children }) {
                                    if (!!children?.length) {
                                        children.forEach((child, index) => {
                                            if (typeof child === "string") {
                                                children[index] = convertMarkdownLinksToReactElements(child);
                                            }
                                        })
                                    }
                                    return createElement("li", {}, children || [])
                                }
                            }}
                            />
                        </div>
                    </div>

                    <div className="row" style={{ paddingTop: '35px'}}>
                        <div className="col-4 bld-infoheading">
                            <div className="rsection3title">
                                <div className="rsection3titleCont">{data.heading1}</div>
                            </div>
                        </div>
                        
                        <div className="col-8 bld-desc-container">
                        <div>
                            <ReactMarkdown className="conclusion" children={data.conclusion} rehypePlugins={[rehypeRaw]} components={{
                                p({ children }) {
                                    if (!!children?.length) {
                                        children.forEach((child, index) => {
                                            if (typeof child === "string") {
                                                children[index] = convertMarkdownLinksToReactElements(child);
                                            }
                                        })
                                    }
                                    return createElement("p", {}, children || [])
                                  }
                            }} />
                        </div>
                        </div>
                    </div>
                </div>               
              
            </div>
        </div>
    )
}

export default SpeakerList
